/* You can add global styles to this file, and also import other style files */
/* Application-wide Styles */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
/*@import '~roboto-fontface/css/roboto/roboto-fontface.css';*/
@import url('https://fonts.googleapis.com/css?family=Palatino');
@import url('https://unpkg.com/bootstrap@4.3.1/dist/css/bootstrap.min.css');
h1 {
    color: #369;
    font-family: 'Palatino';
    font-size: 250%;
  }
  h2, h3 {
    color: #444;
    font-family:'Palatino';
    font-weight: lighter;
  }
  body {
    margin: 2em;
  }
  body, input[type="text"], button {
    color: #888;
    font-family: 'Palatino';
  }
 

  .g-signin2{
    width: 100%;
    
  }
  
  .g-signin2 > div{
    margin: 0 auto;
  }
  /* everywhere else */
  * {
    font-family: 'Palatino';
  }

  
 /* .mat-table {
    overflow: auto;
    max-height: 500px;
  }*/
.mat-table__wrapper .mat-table {
    min-width: auto !important;
    width: 100% !important;
}

.mat-header-row {
    width: 100%;
}

.mat-row {
    width: 100%;
}
  .mat-table mat-column{
  border-right: 1px solid grey;
  align-self: stretch;
  text-align: center


  }
  
  mat-cell, .mat-cell {
    display:flex; 
    justify-content:center;
    
  }
 
  mat-header-cell, .mat-header-cell{
    display:flex;
    justify-content:center;
    
    
    
  }
  mat-column, .mat-column{
    flex: 0 0 25% !important;
    min-width: 104px !important;
  }
  .mat-cell.mat-column-picture.ng-star-inserted.img{
    display: flex;
    border: groove 4px blueviolet;
    justify-content: center;
    
  }
  .cont {
    display: flex;
    justify-content: center;
   
  }
  .center{
    position: absolute;
    width: 300px;
    height: 200px;
    z-index: 15;
    top: 50%;
    left: 50%;
    margin: -100px 0 0 -150px;
  }
  .myCustomModalClass .modal-dialog {
    max-width: 800px;
  }
  .myCustomVLModalClass .modal-dialog {
    max-width: 90%;
  }

  .loadingOverlay {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
  }
  
  .loadingSpinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
  }

  .mat-form-field-flex {
    padding-top: 0 !important;
  }

  .imageGS {
    width: auto !important; /*override the width below*/
    width: 100%;
    max-width: 300px;
  }

  .imageGS:hover {
    cursor: zoom-in;
  }

  .imagePopupOverlay {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 2;
    cursor: pointer;
  }
  
  .imagePopup {
    width: auto !important;
    width: 100%;
    max-width: 900px;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    top: 15%;
    transition: transform .3s;
  }
  
  /* zoom image while cursor is over*/
  .imagePopup:hover {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari 3-8 */
    transform: scale(1.5); 
  }

  /* The image popup Close Button */
  .close-cross {
    color: white;
    position: absolute;
    right: 60px;
    font-size: 125px;
    font-weight: bold;
  }

  .close-cross:hover,
  .close-cross:focus {
    color: red;
    text-decoration: none;
    cursor: pointer;
  }
  
  .form-control-edit {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #0000000f;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

/*map*/
svg {
  font: 10px sans-serif;
}

.vis-title {
  font: 14px sans-serif;
  font-weight: bold;
  fill: #777;
}

.boroughs {
  fill: rgb(222,235,247);
  stroke: #000;
  stroke-width: 0.5px;
}

.tooltip {
  position: absolute;
  font-size: 12px;
  width:  auto;
  height: auto;
  pointer-events: none;
  background-color: white;
}

.circle-hover {
  stroke: #000;
  stroke-width: 1px;
}

.area {
  fill: steelblue;
  fill-opacity: 0.7;
}

.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

.brush .extent {
  stroke: #fff;
  fill: #000;
  fill-opacity: .25;
  shape-rendering: crispEdges;
}

.tooltipBC{
  position      : absolute;
  text-align    : center;
  max-width     : 100px;
  max-height    : 100px;
  padding       : 8px;
  border        : none;
  border-radius : 8px;
  margin-top    : -30px;
  font          : 10px sans-serif;
  background    : #007bff;
  color         : white;
  pointer-events: none;
}

#map-container {
  display: block;
}
#barchart-container {
  display: none;
  margin: auto;
}
.tag-map-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 6px;
  overflow-x: hidden;
}
.tag-map-container .tag {
  height: 30px;
  margin: 5px;
  padding: 5px 6px;
  border-radius: 9px;
  background: #007bff;
  display: flex;
  align-items: center;
  color: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2), inset 0 1px 1px #fff;
  cursor: default;
}

.tag-map-container .tag:hover {
  background: #0069d9;
}

.tag-map-container .tags-select {
  height: 30px;
  margin: 5px;
  padding: 5px 6px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: #eee;
  display: flex;
  align-items: center;
  color: #333;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2), inset 0 1px 1px #fff;
  cursor: default;
}
.tag i {
  font-size: 16px;
  color: white;
  margin-left: 5px;
}
.tag-map-container input {
  padding: 5px;
  font-size: 16px;
  border: 0;
  outline: none;
  font-family: 'Rubik';
  color: #333;
  flex: 1;
}


/*newmap*/
#map {
  width:95vw;
  height:37vw;
  margin: auto;
  z-index: 1;
}
#legendContainer {
  position: absolute;
  top: 18vw;
  left: 83vw;
  margin: 10px;
  padding: 5px;
  border-radius: 5px;
  z-index: 100;
  font-size: 0.5em;
  font-family: sans-serif;
  width: 200px;
  background: rgba(255,255,255,0.6);
}
#legend {
  height: 25px;
  padding: 8px 2px 8px 2px;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: bold;
  position: relative;
}
.legendheading {
  position: relative;
  height: 25px;
  padding: 8px 2px 8px 2px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
}
.legenditem {
  padding: 2px;
  margin-bottom: 2px;
}
.tagFieldLegend{
  margin: 5px 0px 5px 0px;
  border-bottom: 1px solid black;
  position:relative
}
.tagFieldName{
  font-weight: bold;
}


/*Marker clusters*/
.marker-cluster-pie g.arc{
  fill-opacity: 0.5;
}
.marker-cluster-pie-label {
  font-size: 14px;
  font-weight: bold;
  font-family: sans-serif;
}

/*Markers*/
.marker {
  width: 18px;
  height: 18px;
  border-width: 2px;
  border-radius:10px;
  margin-top: -10px;
  margin-left: -10px;
  border-style: solid;
  fill: #CCC;
  stroke: #444;
  background: #CCC;
  border-color: #444;
}
.marker div{
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  font-family: sans-serif;
}

/*marker categories*/
.category-1{
  fill: #F88;
  stroke: #800;
  background: #F88;
  border-color: #800;
}
.category-2{
  fill: #FA0;
  stroke: #B60;
  background: #FA0;
  border-color: #B60;
}
.category-3{
  fill: #FF3;
  stroke: #D80;
  background: #FF3;
  border-color: #D80;
}
.category-4{
  fill: #BFB;
  stroke: #070;
  background: #BFB;
  border-color: #070;
}
.category-5{
  fill: #9DF;
  stroke: #007;
  background: #9DF;
  border-color: #007;
}
.category-6{
  fill: #CCC;
  stroke: #444;
  background: #CCC;
  border-color: #444;
}

/*marker icons*/
/*.icon-1{
  background-image: url('running-16.png');
  background-repeat: no-repeat;
  background-position: 0px 1px;
}
.icon-2{
  background-image: url('bicycle-16.png');
  background-repeat: no-repeat;
  background-position: 1px 0px;
}
.icon-3{
  background-image: url('motorcycle-16.png');
  background-repeat: no-repeat;
  background-position: 1px 0px;
}
.icon-4{
  background-image: url('car-16.png');
  background-repeat: no-repeat;
  background-position: 1px 0px;
}*/

/*Popup*/
.map-popup span.heading {
  display: block;
  font-size: 1.2em;
  font-weight: bold;
}
.map-popup span.attribute {
  display: block;
}
.map-popup span.label {
  font-weight: bold;
}

/* sidenav */
.sidenav {
  height: 75%;
  width: 0;
  position: absolute;
  z-index: 1;
  top: 224px;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 10px;
  border-radius: 2px;
}

/* Style the sidenav links and the dropdown button */
.sidenav a, .dropdown-btn {
  padding: 0px 8px 6px 16px;
  text-decoration: none;
  font-size: 24px;
  color: black;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}

/* On mouse-over */
.sidenav a:hover, .dropdown-btn:hover {
  color: #818181;
}

/* Add an active class to the active dropdown button */
.active {
  background-color: #0069d9;
  transition: background-color .5s;
  color: white;
}

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container {
  display: none;
  background-color: White;
  padding: 0px 0px 8px 8px;
  border-bottom: 1px solid #818181;
}

#sidenavHeader {
  font-size: 36px;
  float: left;
  padding-left: 8px;
}

#sidenavContent1 {
  margin-top: 10px;
}


#timelineFrom, #timelineTo, #btnTimelineAges {
  width: 30%;
  margin: 5px 5px 5px 5px;
}

#filterTypeCity-container {
  display: none;
}
.originCheckBox {
  width: 100%;
  margin: 5px;
  font-size: 24px;
  padding: 0px 8px 6px 10px;
}
.provenanceCheckBox{
  width: 100%;
  margin: 5px;
  font-size: 24px;
  padding: 0px 8px 6px 10px;
}
.originCheckBox > input[type='checkbox'] {
  -webkit-appearance:none;
  width:20px;
  height:20px;
  background:white;
  border-radius:5px;
  border:2px solid #555;
}

.originCheckBox > input[type='checkbox']:checked {
  background:#0069d9;
}
.provenanceCheckBox > input[type='checkbox']:checked {
  background:#0069d9;
}
.provenanceCheckBox > input[type='checkbox'] {
  -webkit-appearance:none;
  width:20px;
  height:20px;
  background:white;
  border-radius:5px;
  border:2px solid #555;
}
.provenanceCheckBox > span, .originCheckBox > span{
  padding-right: 16px;
}


#filterTagsDocs-container, #filterTime-container {
  display: none;
}

.sidenav .closebtn {
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 100px;
  width: 66px;
  float: right;
}

#panel {
  transition: margin-left .5s;
}

.clusterPopup{
  height: 100px;
  overflow-y: scroll;
}

.mapTitle {
  font-size:18px; 
  margin-left: 20px;
}
.navBar{
  float:right;
}

.mapTitleFilters{
  font-size: 0.5em;
  margin-left: 5px;
}

#titleName {
  font-size: 25px;
}

#docButton{
  margin-right: 10px;
}
#resetFilters {
  background-color: #dc3545;
  color: white;
  margin: 5px 5px 5px 10px;
  border-radius: 10px;
}
#resetFilters:hover {
  background-color: #c82433;
}
#psButton, #showMap{
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
#docButton, #showBarChart {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.unactive{
  background-color: #eeeeee;
  color: #617d8b;
}


/*.legend {
  float: right;
  font-size: 12px;
}

#legend {
  width: 300px;
}*/

.box {
  float: right;
  height: 20px;
  width: 20px;
  margin-left: 8px;
  margin-bottom: 15px;
  border: 2px solid black;
  border-radius: 20px;
  clear: both;
}

.ori {
  background-color: #F88;
  border-color: #800;
}

.pro {
  background-color: #FA0;
  border-color: #B60;
}

.conP {
  background-color: #FF3;
  border-color: #B80;
}

/* bar chart*/
#barchart {
  width:95vw;
  height:37vw;
  margin: auto;
  z-index: 1;
}


@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1052 !important;
}